<template>
  <v-container fluid>
    <v-col align-start>

      <!-- Inactivity dialog -->
      <v-flex>
        <v-dialog v-model="inactivityDialog" width="600">
          <v-card class="flex-grow-0 overflow-hidden">
            <v-flex mb-5 mt-2 class="text-center"><h3>Your session is about to expire!</h3></v-flex>
            <v-row class="text-right mb-5 mr-5">
              <v-flex ml-5><v-btn v-if="changes" small color="green white--text" @click="inactive('save')">SAVE CHANGES AND EXIT</v-btn></v-flex>
              <v-flex ml-2><v-btn v-if="changes" small color="red white--text" @click="inactive('exit')">IGNORE CHANGES AND EXIT</v-btn></v-flex>
              <v-flex ml-5><v-btn v-if="!changes" small color="red white--text" @click="inactive('exit')">EXIT</v-btn></v-flex>
              <v-flex ml-2><v-btn small color="purple white--text" @click="inactive('continue')">Keep going</v-btn></v-flex>
            </v-row>
          </v-card> 
        </v-dialog>
      </v-flex>
      
      <!-- Calendar dialog -->
      <v-flex class="text-center">
        <v-dialog v-model="calendar" width="400">
          <v-flex>
            <v-date-picker multiple v-model="selDates">
            <v-spacer></v-spacer>
              <v-btn x-small rounded color="red white--text" @click="calendar=false">cancel</v-btn>
              <v-btn x-small rounded color="green white--text" @click="setDates()">ok</v-btn>
            </v-date-picker>
          </v-flex>
        </v-dialog>
      </v-flex>
      
      <!-- Links dialog -->
      <v-flex>
        <v-dialog v-model="linksDialog" width="800">
          <v-card>
            <v-flex class="text-center"><h3>Attendee links</h3></v-flex>
            <v-col>
              <v-flex mt-3><v-textarea
                id='links'
                v-model="linkField"
                auto-grow
                ></v-textarea></v-flex>
              <v-row>
                <v-flex text-right mb-5 mr-5>
                  <v-btn small rounded color="red white--text" class="mr-5" @click="copyLinks()">copy to clipboard</v-btn>
                  <v-btn small rounded color="green white--text" @click="linksDialog = false">exit</v-btn>
                </v-flex>
              </v-row>
            </v-col>
          </v-card>
        </v-dialog>
      </v-flex>
      
      <!-- Preferred day dialog -->
      <v-flex>
        <v-dialog v-model="preferredDayDlg" width="400">
          <v-card>
            <v-flex class="text-center"><h3>Select the preferred day of the week</h3></v-flex>
            <v-col>
              <v-radio-group v-model="preferredDay" @change="changes=true;preferredDayDlg=false;calculateBestFitDate()">
                <v-radio
                  v-for="n in daysOfWeek.length"
                  :key="n"
                  :label="daysOfWeek[n-1]"
                  :value="daysOfWeek[n-1]"
                ></v-radio>
              </v-radio-group>
              <v-flex class="text-right"><v-btn x-small color="red white--text" @click="preferredDayDlg=false">cancel</v-btn></v-flex>
            </v-col>
          </v-card>
        </v-dialog>
      </v-flex>
      
      <!-- Comment dialog -->
      <v-flex>
        <v-dialog v-model="commentDialog" width="800">
          <v-card>
            <v-flex class="text-center" mt-5><h3>Write/edit a comment relating to this meeting</h3></v-flex>
            <v-col>
              <v-flex my-3><v-text-field
                id='comment'
                v-model="comment"
                ></v-text-field></v-flex>
              <v-row>
                <v-flex text-right mb-5 mr-5>
                  <v-btn small rounded class="mr-5" color="red white--text" @click="commentDialog=false">cancel</v-btn>
                  <v-btn v-if="comment" small rounded color="blue white--text" class="mr-5" @click="comment=''">clear</v-btn>
                  <v-btn small rounded color="green white--text" class="mr-5" @click="saveComment()">save</v-btn>
                </v-flex>
              </v-row>
            </v-col>
          </v-card>
        </v-dialog>
      </v-flex>
      
      <!-- Date tag dialog -->
      <v-flex>
        <v-dialog v-model="dateTagDialog" width="300">
          <v-card>
            <v-flex class="text-center" mt-5><h4>Tag this date (eg Pub Hol)<br>Try to keep short (&lt; 10 chars)</h4></v-flex>
            <v-col>
              <v-flex my-3><v-text-field
                v-model="dateTag"
                ></v-text-field></v-flex>
              <v-row>
                <v-flex text-right mb-5 mr-5>
                  <v-btn x-small rounded class="mr-5" color="red white--text" @click="dateTagDialog=false">cancel</v-btn>
                  <v-btn v-if="dateTag" x-small rounded class="mr-5" color="blue white--text" @click="dateTag=''">clear</v-btn>
                  <v-btn x-small rounded color="green white--text" class="mr-5" @click="saveDateTag()">save</v-btn>
                </v-flex>
              </v-row>
            </v-col>
          </v-card>
        </v-dialog>
      </v-flex>
      
      <!-- Meeting dialog -->
      <v-flex class="text-left" v-if="meetingView">
          <v-card class="flex-grow-0 overflow-hidden">
            <v-card-title
              class="headline grey lighten-2"
              primary-title
            >
              <v-flex v-if="$vuetify.breakpoint.xsOnly" xs10 class="text-center"><h8><font color="blue">PLANSHAREMEET - A SIMPLE MEETING ORGANISER</font></h8></v-flex>
              <v-flex v-if="$vuetify.breakpoint.smAndUp" xs10 class="text-center"><h6><font color="blue">PLANSHAREMEET - A SIMPLE MEETING ORGANISER</font></h6></v-flex>
              <v-flex><h6>&copy; Tom Smith 2022</h6></v-flex>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-flex v-if="!organiser && !create" mt-10 ml-2 xs4><h4>Meeting name: <font color="blue">{{meetingName}}</font></h4></v-flex>
                <v-flex v-if="organiser" mt-5 ml-5 xs4><v-text-field
                  id='name'
                  label='Meeting name'
                  v-model="meetingName"
                  @change="changes=true"
                  ></v-text-field></v-flex>
                <v-flex v-if="!organiser" mt-10 ml-3 xs4><h4>Location: <font color="blue">{{location}}</font></h4></v-flex>
                <v-flex v-if="organiser" mt-5 ml-5 xs4><v-text-field
                  id='location'
                  label='Meeting location'
                  v-model="location"
                  @change="changes=true"
                  ></v-text-field></v-flex>
                <v-flex v-if="!organiser && meetingTime" mt-10 ml-3 mr-5 xs2><h3>Time: <font color="blue">{{meetingTime}}</font></h3></v-flex>
                <v-flex v-if="organiser" mt-5 ml-5 xs2>
                  <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="meetingTime"
                    persistent
                  width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-flex><v-text-field
                        v-model="meetingTime"
                        label="Meeting time"
                        prepend-icon="mdi-clock"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field></v-flex>
                    </template>
                    <v-time-picker
                      v-if="modal2"
                      v-model="meetingTime"
                      full-width
                    >
                      <v-btn text color="primary" @click="$refs.dialog.save('');changes=true;modal2 = false">No time</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(meetingTime);changes=true">OK</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-flex>
              </v-row>
              <v-row mt-5 mb-n5>
                <v-flex>
                  <v-textarea
                    filled dense rows=2 auto-grow class="caption" clear-icon="mdi-close" color="black"
                    label="Meeting notes"
                    :readonly="!organiser"
                    :clearable="organiser"
                    v-model="meetingNotes"
                    @change="changes=true">
                  </v-textarea>
                </v-flex>
              </v-row>

              <v-flex my-3><v-divider></v-divider></v-flex>

              <v-row class="mb-1">
                <v-flex mt-5 xs2></v-flex><v-flex mt-5 ml-10 xs1><h4>Dates</h4></v-flex>
                <v-flex v-if="organiser && !nameField" mt-5 xs1>
                  <v-btn x-small rounded class="blue white--text" @click="calendar=true">ADD DATE(S)</v-btn></v-flex>
                <v-flex v-if="(create || organiser) && !nameField && !calendar && nbrAttendees > 0" mt-5 ml-8>
                  <v-btn x-small rounded class="purple white--text" @click="preferredDayDlg=true">Set preferred day</v-btn></v-flex>
              </v-row>

<!-- Date tags -->
              <v-row v-if="haveDateTag()">
                <v-flex xs2 mb-n2>&nbsp;</v-flex>
                <v-flex v-for="n in nbrDisplayDates" :key="n" class="text-center" xs1 mb-n1>
                    <v-flex style="font-size:12px">{{dateTags[dateOffset+n-1]}}</v-flex>
                </v-flex>
              </v-row>
<!-- Date tags -->

<!-- Week day -->
              <v-row>
                <v-flex xs2 mb-n2></v-flex>
                <v-flex v-for="n in nbrDisplayDates" :key="n" class="text-center" xs1 mb-n1>
                    <v-flex :style="dateColour(dates[dateOffset+n-1].date)" style="font-size:12px">{{dayOfWeek(dates[dateOffset+n-1].date)}}</v-flex>
                </v-flex>
              </v-row>
<!-- Week day -->

              <v-row>
                <v-flex mt-n1 xs1><h4>Attendees</h4></v-flex>
                <v-flex v-if="dateOffset == 0" xs1></v-flex>
                <v-flex v-if="dateOffset > 0" mt-n3 xs1 class="text-center">
                  <v-btn x-small class="blue white--text" @click="earlierDates()" style="font-size:8px">&lt;&lt;</v-btn>
                </v-flex>
<!-- Dates -->
                <v-flex v-for="n in nbrDisplayDates" :key="n" class="text-center" xs1>
                
                  <v-menu
                  :close-on-click=true
                  nudge-right="30"
                  nudge-top="75"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-flex v-bind="attrs" v-on="on" :style="dateColour(dates[dateOffset+n-1].date)" style="font-size:12px">
                        {{formatDate(dates[dateOffset+n-1].date, !intDateFormat)}}</v-flex>
                    </template>
                    <v-list v-if="organiser">
                      <v-list-item
                        v-for="(item, index) in dateMenu"
                        :key="index"
                        :disabled="dateMenuDisabled(dateOffset+n-1, index)"
                        @click="dateMenuAction(dateOffset+n-1, index)"
                        >
                        <v-list-item-title>{{ dateMenuText(dateOffset+n-1, index) }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  
                </v-flex>
<!-- Dates -->
                <v-flex v-if="nbrDates > dateOffset+8" mt-n3 xs1 class="text-center">
                  <v-btn x-small class="blue white--text" @click="laterDates()" style="font-size:8px">&gt;&gt; more dates</v-btn>
                </v-flex>
              </v-row>

              <v-flex mb-4></v-flex>
<!-- Table -->
              <v-flex v-for="a in nbrAttendees" :key="a" class="mb-5">
                <v-row>
                  <v-flex xs2 :class="attSpacing(a-1)">
                    <v-row>
                      <v-menu
                      :close-on-click=true
                      nudge-right="50"
                      nudge-top="100"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-flex v-bind="attrs" v-on="on" :class="userColour(a-1)" style="font-size:12px">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{attendeeText(a-1)}}</v-flex>
                        </template>
                        <v-list v-if="organiser">
                          <v-list-item
                            v-for="(item, index) in attendeeMenu"
                            :key="index"
                            :disabled="attendeeMenuDisabled(a-1, index)"
                            @click="attendeeMenuAction(a-1, index)"
                            >
                            <v-list-item-title>{{ attendeeMenuText(a-1, index) }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-row>
                  </v-flex>                
                  <v-flex v-for="d in nbrDisplayDates" :key="d" xs1>
                    <v-btn block x-small :class="availColour(a-1,dateOffset+d-1)" @click="setAvailability(a-1, dateOffset+d-1)"></v-btn>
                  </v-flex>
                  <v-flex v-if="organiser || attendees[a-1] == myName" ml-3 xs1><v-btn x-small block @click="editComment(a-1)">{{commentBtnText(a-1)}}</v-btn></v-flex>
                </v-row>
                <v-flex v-if="comments[a-1]" mt-3 mb-n5 style="font-size:12px">&#8679;&nbsp;{{comments[a-1]}}</v-flex>
              </v-flex>
<!-- Table -->

              <v-row>
                <v-flex v-if="organiser && !nameField" mt-3 ml-3 xs2>
                  <v-btn x-small rounded class="blue white--text" @click="nameField=true">ADD ATTENDEE</v-btn></v-flex>
                <v-flex v-if="!nameField" class="text-left" style="color:blue" mt-4><h4>Current best fit date is: 
                  {{dayOfWeek(bestFit)}} {{bestFit}} : {{bestFitText}}</h4></v-flex>
              </v-row>
            
              <v-row v-if="nameField">
                <v-flex ml-5 xs4><v-text-field
                  id='newName'
                  label='Enter an attendee name'
                  v-model="newName"
                  v-on:keyup.enter="addNewAttendee()"
                  ></v-text-field></v-flex>
                <v-flex mt-5 ml-5 xs1><v-btn v-if="nameField" :disabled="newName==''" x-small rounded class="blue white--text" 
                  @click="addNewAttendee()">ADD</v-btn></v-flex>
                <v-flex mt-5 xs1><v-btn v-if="nameField" x-small rounded class="red white--text" @click="nameField=false;newName=''">FINISH</v-btn></v-flex>
              </v-row>
              <v-flex v-if="chosenDate" mt-8 class="yellow accent-2">
                <h3>Chosen date is: {{dayOfWeek(chosenDate)}} {{chosenDate}} : {{chosenText}}</h3></v-flex>
            </v-card-text>

            <v-flex my-3><v-divider></v-divider></v-flex>

            <v-card-actions>
              <v-row class="justify-end mb-3 mr-3">
                  <v-btn v-if="locked"  
                    small rounded class="orange white--text" @click="forceUnlock()">Force unlock</v-btn>
                  <v-btn v-if="!create && organiser && !nameField && !calendar && nbrAttendees > 0"  
                    small rounded class="purple white--text ml-10" @click="createLinks">Generate links</v-btn>
                  <v-btn v-if="!nameField && !calendar" small class="red white--text ml-10" 
                    @click="cancel()">CANCEL/EXIT</v-btn>
                  <v-btn v-if="changes && !readOnly && !nameField && !calendar" small class="green white--text ml-5" 
                    @click="saveMeeting()">SAVE</v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
      </v-flex>  

      <!-- Create meeting dialog -->
      <v-flex>
        <v-dialog v-model="createDialog" width="600">
          <v-card>
            <v-flex class="text-center"><h3>Create a new meeting</h3></v-flex>
            <v-col>
              <v-row class="mx-5 mb-n8">
                <v-text-field
                  label='Your name'
                  v-model="newName"
                  ></v-text-field>
                <v-checkbox class="mt-5 mx-3"
                  dense
                  v-model="organiserOnly[0]"
                  label="Organiser only, I am not attending"
                  :value="organiserOnly[0]"
                ></v-checkbox>
              </v-row>              
              <v-row class="mx-5 mb-n8"><v-text-field
                label='Meeting name'
                v-model="meetingName"
                ></v-text-field></v-row>
              <v-row class="mx-5 mb-n8"><v-text-field
                label='Meeting location'
                v-model="location"
                ></v-text-field></v-row>
              <v-row class="mx-5 mb-n3">
                <v-flex mt-5 mr-5 xs5><span>Nominal (preferred) date:</span></v-flex>
                <v-dialog
                  ref="dialog2"
                  v-model="modal3"
                  :return-value.sync="nominalDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-flex xs3><v-text-field
                      v-model="nominalDateDisplay"
                      label="date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field></v-flex>
                  </template>
                  <v-date-picker
                    v-if="modal3"
                    v-model="nominalDate"
                    full-width
                    @change="$refs.dialog2.save(nominalDate);nominalDateDisplay=formatDate(vueToIntDate(nominalDate), !intDateFormat)"
                  >
                  </v-date-picker>
                </v-dialog>
              </v-row>
              <v-row class="mx-5 mb-n8">
                <v-flex mt-2 xs5><span>Preferred day (optional):</span></v-flex>
                <v-flex xs2 mt-n3 ml-5><v-text-field
                  v-model="preferredDay"
                  :disabled=true
                ></v-text-field> </v-flex>
                <v-flex mt-2 ml-5><v-btn x-small @click="preferredDayDlg=true">change</v-btn></v-flex>
              </v-row>
              <v-row class="mx-5">
                <v-flex mt-5 mr-5 xs5><span>Meeting time (optional):</span></v-flex>
                <v-dialog
                  ref="dialog"
                  v-model="modal2"
                  :return-value.sync="meetingTime"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-flex xs3><v-text-field
                      v-model="meetingTime"
                      label="time"
                      prepend-icon="mdi-clock"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field></v-flex>
                  </template>
                  <v-time-picker
                    v-if="modal2"
                    v-model="meetingTime"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.dialog.save(meetingTime)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
                <v-flex mt-5 ml-2 v-if="meetingTime"><v-btn x-small @click="meetingTime=''">clear</v-btn></v-flex>
              </v-row>
              <v-row class="mx-5 mb-n10"><p>Date format:</p></v-row>
              <v-row class="mx-5 mb-n8">
                <v-radio-group v-model="intDateFormat" row @change="nominalDateDisplay=formatDate(vueToIntDate(nominalDate), !intDateFormat)">
                  <v-radio
                    label="International i.e. day/month/year"
                    :value=true
                  ></v-radio>
                  <v-radio
                    label="US i.e. month/day/year"
                    :value=false
                  ></v-radio>
                </v-radio-group>
              </v-row>
              <v-row class="mx-5 mb-n8"><v-textarea
                label="Meeting notes (optional)"
                v-model="meetingNotes"
                auto-grow
                ></v-textarea></v-row>
              <v-row>
                <v-flex text-right my-5 mr-5>
                  <v-btn x-small rounded color="red white--text" class="mr-5" @click="meetingName='';createDialog=false">cancel</v-btn>
                  <v-btn x-small rounded color="green white--text" @click="createMeeting()">create</v-btn>
                </v-flex>
              </v-row>
            </v-col>
          </v-card>
        </v-dialog>
      </v-flex>
      
  <!-- Main page -->
      <v-form v-if="!meetingView">
        <v-col class="text-center">
          <v-flex mb-10><h2>PlanShareMeet - A simple meeting organiser for business or pleasure</h2></v-flex>
          <v-row><v-flex xs3></v-flex><v-flex xs6><v-text-field
            label="Meeting name"
            v-model="meetingName"
            ></v-text-field></v-flex></v-row>
          <v-flex><br><h4>OR</h4></v-flex>
          <v-row><v-flex xs3></v-flex><v-flex xs6><v-text-field
            label="Meeting ID"
            v-model="meetingId"
            ></v-text-field></v-flex></v-row>
          <v-row><v-flex xs4></v-flex><v-flex xs4 mt-n3><v-text-field
            label="User PIN"
            v-model="userId"
            :type="'password'"
            autocomplete="username"
          ></v-text-field></v-flex></v-row>
          <v-row><v-flex xs3></v-flex><v-flex xs6 mt-2><v-btn :disabled="meetingName=='' && meetingId==''" small block class="green white--text" 
            @click="openMeeting()">OPEN MEETING</v-btn></v-flex></v-row>
          <v-row><v-flex xs3></v-flex><v-flex xs6 mt-5><v-btn :disabled="meetingName=='' && meetingId==''" small block class="red white--text" 
            @click="deleteMeeting()">DELETE MEETING</v-btn></v-flex></v-row>
          <v-row><v-flex xs3></v-flex><v-flex xs6 mt-15><v-btn small block class="blue white--text" 
            @click="newMeeting()">CREATE MEETING</v-btn></v-flex></v-row>
        </v-col>
      </v-form>
    </v-col>
  </v-container>
</template>

<script>

  const INACTIVITY_TIMER = 3;    // minutes
  const RESPONSE_TIMEOUT = 30;   // seconds
  const CONNECT_TIME = 1;        // minutes

  export default {
    name: 'MeetingsView',
    data () {
      return {
        db: window.db,
        meetingView: false,
        dialog: false,
        meetingName: "",
        meetingId: "",
        userId: "",
        edit: false,
        create: false,
        nameText: "",
        dates: [],
        nbrDates: 0,
        attendees: [],
        nbrAttendees: 5,
        myName: "",
        organisers: [],
        organiser: false,
        readOnly: true,
        location: "",
        myDocName: "",
        changes: false,
        calendar: false,
        picker: "",
        selDates: [],
        nameField: false,
        newName: "",
        pins:[],
        organiserName: "",
        nominalDate: "",
        preferredDay: "None",
        linkField: "",
        linksDialog: false,
        preferredDayDlg: false,
        daysOfWeek: ["None", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        bestFit: "",
        bestFitText: "",
        comments: [],
        comment: "",
        commentDialog: false,
        commentIx: 0,
        isOrganiser: false,
        isRequired: false,
        required: [],
        ready: false,
        organiserOnly: [false],
        createDialog: false,
        meetingNotes: "",
        intDateFormat: true,
        meetingTime: "",
        modal2: false,
        modal3: false,
        nominalDateDisplay: "",
        attendeeMenu: [
          { name: 'Remove from meeting' },
          { name: 'Set as required attendee' },
          { name: 'Set as organiser' },
          { name: 'Set as organiser only' },
        ],
        dateMenu: [
          { name: 'Delete date' },
          { name: 'Set as nominal date' },
          { name: 'Add tag to date' },
          { name: 'Set as chosen date' },
        ],
        dateTags: [],
        dateTag: "",
        dateTagDialog: false,
        dateIx: 0,
        dateOffset: 0,
        nbrDisplayDates: 0,
        maxDisplayDates: 8,
        chosenDate: "",
        chosenText: "",
        locked: false,
        interval: "",
        inactivityDialog: false,
        responseInterval: "",
        connectInterval: "",
        connectTimes: [],
      }
    },
    watch: {
    },
    methods: {
      async sleep(milliseconds) {
          return new Promise(resolve => setTimeout(resolve, milliseconds))
      },
      newMeeting() {
        //this.newName = this.meetingName = this.location = this.meetingTime = this.meetingNotes = "";
        this.newName = "";
        this.meetingName = "";
        this.location = "";
        this.meetingNotes = ""
        this.meetingTime = "";
        this.nominalDate = "";
        this.nominalDateDisplay = "";
        this.preferredDay = "None";
        this.meetingTime = "";
        this.dateTags = [];
        this.intDateFormat = true;
        this.organiserOnly = [false];
        this.createDialog=true;               
      },
      async createMeeting() {
        // Check we have everything
        if (!this.newName) {alert("Please enter your name");return}
        if (!this.meetingName) {alert("Please enter a meeting name");return}
        if (!this.location) {alert("Please enter a meeting location");return}
        if (!this.nominalDate) {alert("Please select a nominal (preferred) date for the meeting");return}
        // Check meeting name is unique
        var docRefs = await this.db.collection("meetings").get();
        for (var i=0 ; i < docRefs.docs.length ; i++) {
          if (docRefs.docs[i].get("title") != this.meetingName)
            continue;
          // Match - must be unique
          alert("The meeting name: '" + this.meetingName + "' already exists. Please set a different name");
          return;
        }
        this.ready = false;
        this.attendees = [this.newName];
        this.newName = "";
        this.nominalDate = this.vueToIntDate(this.nominalDate);
        this.dates = [{date: this.nominalDate, availability: ['U']}];
        this.meetingId = "";
        this.userId = "";
        this.nbrDates = 1;
        this.nbrDisplayDates = 1;
        this.dateTags = [""];
        this.nbrAttendees = 1;
        this.organisers = [true];
        this.required = [false];
        this.pins = [this.setPIN()];
        this.organiser = true;
        this.readOnly = false;
        this.create = true;
        this.nameText = "Enter a name for this meeting";
        this.comments = [""];
        this.ready = true;
        this.changes = true;   
        this.createDialog = false;  
        this.meetingView = true;
      },
      hasCurrentUser() {
        var today = new Date();
        var ms = Date.UTC(today.getFullYear(), today.getMonth()+1, today.getDate(), today.getHours(), 
                                  today.getMinutes(), today.getSeconds());
        const stillInUse = ms - 5 * 60 * 1000;       // Last connect within last 5 minutes
        for (var i=0 ; i < this.connectTimes.length; i++) {
          if (this.connectTimes[i] > stillInUse)
            return(true);
        }
        return(false);
      },
      async openMeeting() {
        if (!this.meetingName && !this.meetingId) {
          alert("Enter a meeting name OR id");
          return;
        }
        this.ready = false;
        this.create = false;
        this.readOnly = false;
        this.myName = "";
        this.organiser = false;
        this.comments = [];
        this.required = [];
        this.organisers = [];
        this.organiserOnly = [];
        this.connectTimes = [];
        var meetingRef;
        var data;
        if (this.meetingId) {
          meetingRef = this.db.collection("meetings").doc(this.meetingId);
          var doc = await meetingRef.get();
          if (!doc.exists) {
            alert("No meeting with document id: " + this.meetingId + " found");
            return;
          }  
          data = doc.data();
        }
        else {
          var docRefs = await this.db.collection("meetings").get();
          var found = false;
          for (var i=0 ; i < docRefs.docs.length ; i++) {
            if (docRefs.docs[i].get("title") != this.meetingName)
              continue;
            this.meetingId = docRefs.docs[i].id;
            meetingRef = this.db.collection("meetings").doc(this.meetingId);
            console.log("MATCHED meeting name, doc ID = " + this.meetingId);
            found = true;
            data = docRefs.docs[i].data();
            break;
          }
          if (!found) {
            alert("No meeting with this name found...");
            return;
          }
        }
        this.changes = false;
        this.location = data.location;
        this.nameText = this.meetingName = data.title;
        this.nominalDate = data.nominalDate;
        this.preferredDay = data.preferredDay ? data.preferredDay : "None";
        this.meetingTime = data.meetingTime ? data.meetingTime : "";
        this.meetingNotes = data.meetingNotes ? data.meetingNotes : "";
        this.chosenDate = data.chosenDate ? data.chosenDate: "";
        this.attendees = [];
        this.dates = [];
        this.dateTags = [];
        for (var i=0 ; i < data.dates.length ; i++) {
          this.dates.push({date: data.dates[i], availability: []});
          if (data.dateTags)
            this.dateTags[i] = data.dateTags[i];
          else
            this.dateTags[i] = "";
        }
        this.nbrDates = this.dates.length;
        this.dateOffset = 0;
        this.nbrDisplayDates = Math.min(this.maxDisplayDates, this.nbrDates);
        for (var i=0 ; i < data.names.length ; i++) {
          this.attendees[i] = data.names[i];
          var docName = this.attendees[i].replace(/\s/g, '_');
          // Read availability subcollection for this attendee
          var availRef = meetingRef.collection("availability");
          var nameRef = availRef.doc(docName);
          var availDoc = await nameRef.get();
          if (!availDoc.exists) {
            alert("Database error - no availability data found for " + this.attendees[i] + "!");
            return;
          } 
          var availData = availDoc.data();
          for (var j=0 ; j < this.nbrDates ; j++) {
            if (!availData.organiserOnly && j < availData.availability.length)
              this.dates[j].availability[i] = availData.availability[j];
            else
              this.dates[j].availability[i] = 'U';
          }
          this.pins.push(availData.pin);
          this.comments.push(availData.comment ? availData.comment : "");
          this.organisers.push(availData.organiser ? availData.organiser : false);
          this.required.push(availData.required ? availData.required : false);
          this.organiserOnly.push(availData.organiserOnly ? availData.organiserOnly : false);
        }
        // Check user PIN if provided
        var userIx = -1;
        if (this.userId) {
          for (var i=0 ; i < this.pins.length ; i++) {
            if (this.pins[i] == this.userId)  { 
              userIx = i;
              break;      
            }   
          }
          if (userIx == -1) {
            alert("Invalid user PIN - opening in READ ONLY mode");
            this.readOnly = true;
          }
        }
        else {
          if (!confirm("No user PIN entered - do you want to open the meeting in read only mode?\nOr click CANCEL to enter a PIN"))
            return;
          this.readOnly = true;
        }
        this.nbrAttendees = this.attendees.length;
        if (!this.readOnly || false) { /////////////////////////////// TEMP
          this.myName = data.names[userIx];
          this.organiser = this.organisers[userIx];
          // Lock the document if organiser otherwise check if locked
          const inuse = await this.db.runTransaction(async (transaction) => {
            const snapshot = await transaction.get(meetingRef);
            // Check if someone active
            var today = new Date();
            var ms = Date.UTC(today.getFullYear(), today.getMonth()+1, today.getDate(), today.getHours(), 
                              today.getMinutes(), today.getSeconds());
            var lastConnect = snapshot.data().lastConnect ? snapshot.data().lastConnect : 0;
            var limit = ms - 5 * 60 * 1000;
            if (this.organiser) {
              if (lastConnect > limit)
                // Likely in use so can't make meeting changes
                return(false);
              // Set locked flag - if it succeeds, we have locked it
              await transaction.update(meetingRef, {locked: true, lastConnect: ms});        
            }
            else {
              // Ensure the organiser hasn't locked the doc
              if (snapshot.data().locked) {
                // Check if expired connection
                if (snapshot.data().lastConnect > limit)
                  // Locked so set to read only
                  return(false);
                // Reset locked flag - if it fails, the organiser has jumped in
                await transaction.update(meetingRef, {locked: false, lastConnect: ms});        
              }
              else
                // Set connect to avoid being locked
                await transaction.update(meetingRef, {lastConnect: ms});        
            }
            return(true);
          });
          if (inuse !== true) {
            if (this.organiser) {
              // Someone has the doc open so can only update own availability
              alert("Meeting document in use - you can only update your own availability");
              this.organiser = false;
            }
            else {
              // Locked so set to read only
              this.readOnly = true;
              alert("A meeting organiser has locked the meeting document - opening in READ ONLY mode");
            }
          }
        }
        // Calculate best fit date
        this.calculateBestFitDate();
        this.dialog = true;
        this.ready = true;
        this.meetingView = true;
        if (!this.readOnly) {
          // Set up timer to regularly check for user abscence/continuation
          this.interval = setInterval(this.checkUser, INACTIVITY_TIMER * 60 * 1000);
          // Set up timer to regularly set connect time in DB
          this.connectInterval = setInterval(this.connect, CONNECT_TIME * 60 * 1000);
          await this.connect();
        }
      },
      checkUser() {
        this.responseInterval = setInterval(this.noResponse, RESPONSE_TIMEOUT * 1000);
        this.inactivityDialog = true;
      },
      inactive(resp) {
        if (resp != "continue") {
          if (resp == "save")
            this.saveMeeting();
          this.changes = false;
          this.cancel();
        }
        this.inactivityDialog = false;
        clearInterval(this.responseInterval);
      },
      noResponse() {
        // User didn't respond to inactivity message so just quit (don't save any changes)
        this.inactive("exit");
      },
      async connect() {
        // While a meeting is open update connect time in DB every minute
        if (this.meetingId && this.myName) {
          try {
              var docRef = this.db.collection("meetings").doc(this.meetingId);
              if (docRef) {
                var today = new Date();
                var ms = Date.UTC(today.getFullYear(), today.getMonth()+1, today.getDate(), today.getHours(), 
                                  today.getMinutes(), today.getSeconds());
                await docRef.update({lastConnect: ms});        
              }
          } catch (error) {
              alert("Error accessing database", error);
              return;
          }
        }    
      },
      async saveMeeting() {
        // Save only single attendee data unless organiser where everything is saved
        if (this.organiser) {
          if (this.create) {
            // Check we have everything
            if (!this.meetingName) {alert("No meeting name entered!") ; return}
            if (!this.location) {alert("No meeting location entered!") ; return}
            if (!this.nbrDates) {alert("No meeting dates defined!") ; return}
            if (!this.nbrAttendees) {alert("No attendees added!") ; return}
            if (!this.nominalDate) {alert("Please set a nominal (preferred) meeting date") ; return}
          }
          var datesArray = [];
          var dateTagArray = [];
          for (var dateIx=0 ; dateIx < this.nbrDates ; dateIx++) {
            datesArray.push(this.dates[dateIx].date);
            dateTagArray.push(this.dateTags[dateIx]);
          }
          var meetingData = {
            title: this.meetingName,
            location: this.location,
            dates: datesArray,
            dateTags: dateTagArray,
            names: this.attendees,
            //inUse: 0,
            locked: true,
            nominalDate: this.nominalDate,
            preferredDay: this.preferredDay,
            meetingTime: this.meetingTime,
            meetingNotes: this.meetingNotes,
            chosenDate: this.chosenDate,
          }
          try {
            if (this.meetingId) {
              // Existing meeting being updated
              var docRef = this.db.collection("meetings").doc(this.meetingId);
              await docRef.set(meetingData);
            }
            else {
              // New meeting
              var docRef = this.db.collection("meetings");
              var ref = await docRef.add(meetingData);
              this.meetingId = ref.id;
              console.log("Added new meeting " + this.meetingName + ", ID = " + this.meetingId);
              docRef = this.db.collection("meetings").doc(this.meetingId).collection("availability").doc(docName);
            }
            for (var attIx=0 ; attIx < this.nbrAttendees ; attIx++) {
              var myAvailability = [];
              for (var dateIx=0 ; dateIx < this.dates.length ; dateIx++)
                myAvailability.push(this.dates[dateIx].availability[attIx]);
              var avail = {availability:myAvailability, pin: this.pins[attIx]};
              if (this.comments[attIx])
                avail.comment = this.comments[attIx];
              if (this.organisers[attIx])
                avail.organiser = true;
              if (this.organiserOnly[attIx])
                avail.organiserOnly = true;
              if (this.required[attIx])
                avail.required = true;
              var docName = this.attendees[attIx].replace(/\s/g, '_');
              if (this.meetingId) {
                // Existing meeting being updated
                docRef = this.db.collection("meetings").doc(this.meetingId).collection("availability").doc(docName);
                await docRef.set(avail);
              }
              else {
                // New meeting
                docRef = this.db.collection("meetings").doc(this.meetingId).collection("availability").doc(docName);
                await docRef.set(avail);
              }
            }
          }
          catch (error) {
              alert("Error accessing database", error);
              console.log("DB access error: " + error);
              this.meetingView = false;
              return;
          }
        }
        else {
          // Just update the attendee's availability
          var myIx = this.attendees.indexOf(this.myName);
          var myAvailability = [];
          var allSet = true;
          for (var i=0 ; i < this.dates.length ; i++) {
            myAvailability.push(this.dates[i].availability[myIx]);
            if (this.dates[i].availability[myIx] == 'U')
              allSet = false;
          }
          if (!allSet) {
            if (!confirm("You haven't set availabilty for all dates - are you sure?"))
             return;
          }
          var data = {availability: myAvailability, pin: this.pins[myIx]};
          if (this.comments[myIx])
            data.comment = this.comments[myIx];
          if (this.required[myIx])
            data.required = true;
          if (this.organiser[myIx])
            data.organiser = true;
          if (this.organiserOnly[myIx])
            data.organiserOnly = true;
          var docName = this.myName.replace(/\s/g, '_');
          try {
              var docRef = this.db.collection("meetings").doc(this.meetingId).collection("availability").doc(docName);
              if (docRef)
                await docRef.set(data);
              else {
                alert("Could not update cloud data!");
                this.meetingView = false;
                return;
              }
          } catch (error) {
              alert("Error accessing database", error);
              this.meetingView = false;
              return;
          }
        }
        if (this.create)
          this.createLinks();
        this.edit =this.create = this.dialog = this.changes = false;
        this.userId = "";
      },
      async cancel() {
        if (this.changes || this.create)
          if (!confirm("Are you sure you want to exit and discard the changes?"))
            return;
        if (!this.create) {
          var ref = this.db.collection('meetings').doc(this.meetingId);
          if (this.organiser) {
            // Unlock document
            await ref.update({locked: false, lockId: 0});       
          }
          else if (!this.readOnly) {
            var meetingRef = this.db.collection("meetings").doc(this.meetingId);
            await this.db.runTransaction(async (transaction) => {
              const snapshot = await transaction.get(meetingRef);
              // Decrement inUse counter but ensure it is not < 0
              //if (snapshot.data().inUse < 1)
              //  await transaction.update(meetingRef, {inUse: 0}); 
              //else       
              //  await transaction.update(meetingRef, {inUse: window.fsFunc.FieldValue.increment(-1)});        
            });
          } 
        }
        this.edit =this.create = this.dialog=false;
        this.userId = "";
        this.meetingView = false;
        // Clear timer
        clearInterval(this.interval);
        clearInterval(this.connectInterval);
      },
      async deleteMeeting() {
        var meetingRef;
        var data;
        if ((!this.meetingName && !this.meetingId) || !this.userId) {
          alert("Enter a meeting name OR id plus your PIN");
          return;
        }
        // Check PIN and that user is an organiser
        if (this.meetingId) {
          meetingRef = this.db.collection("meetings").doc(this.meetingId);
          var doc = await meetingRef.get();
          if (!doc.exists) {
            alert("No meeting with document id: " + this.meetingId + " found");
            return;
          }        
          data = doc.data();
        }
        else {
          var docRefs = await this.db.collection("meetings").get();
          var found = false;
          for (var i=0 ; i < docRefs.docs.length ; i++) {
            if (docRefs.docs[i].get("title") != this.meetingName)
              continue;
            this.meetingId = docRefs.docs[i].id;
            meetingRef = this.db.collection("Meetings").doc(this.meetingId);
            found = true;
            data = docRefs.docs[i].data();
            break;
          }
          if (!found) {
            alert("No meeting with this name found...");
            return;
          }
        }
        var matchUser = false;
        for (var i=0 ; i < data.names.length ; i++) {
          this.attendees[i] = data.names[i];
          var docName = this.attendees[i].replace(/\s/g, '_');
          // Read availability subcollection for this attendee
          var availRef = meetingRef.collection("availability");
          var nameRef = availRef.doc(docName);
          var availDoc = await nameRef.get();
          if (availDoc.exists) {
            var availData = availDoc.data();
            if (availData.pin == this.userId) {
              if (!availData.organiser) {
                alert("Only a meeting organiser can delete a meeting!");
                return;
              }
              matchUser = true;
              break;
            }
          }       
        }
        if (!matchUser) {
          alert("Invalid user PIN");
          return;
        }
        if (!confirm("You are about to delete the meeting '" + data.title + "'. All data will be wiped.\nAre you sure?"))
          return;
        // Delete the subcollection first, then the main doc
        for (var i=0 ; i < data.names.length ; i++) {
          var docName = data.names[i].replace(/\s/g, '_');
          var docRef = this.db.collection("meetings").doc(this.meetingId).collection("availability").doc(docName);
          await docRef.delete();
        }
        await meetingRef.delete();
      },
      setPIN() {
        // generate random unique 4 digit PIN
        //var pin = "0000";
        /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/        
        while (true) {
          var r = Math.floor(Math.random() * 10000);
          // set to string
          var pin = "0000" + r;
          pin = pin.slice(-4);
          // Check not used already
          if (this.pins.indexOf(pin) == -1)
            break;
        }
        return(pin);
      },
      pastDate(date) {
        var today = new Date();
        var parts = date.split('/');
        var d = parts[1] + "/" + parts[0] + "/" + parts[2];
        var selDate = new Date(d);
        var t1 = selDate.getTime()/(1000*3600*24)+1;
        var t2 = today.getTime()/(1000*3600*24);
        return(Math.floor(t1) < Math.floor(t2));
      },
      vueToIntDate(date) {
        var parts = date.split('-');
        if (parts.length == 1) return("");
        var day = parts[2];
        if (day[0] == '0') day = day.slice(1);
        var month = parts[1];
        if (month[0] == '0') month = month.slice(1);
        var year = parts[0].slice(2);
        return(day + "/" + month + "/" + year);
      },
      formatDate(date, us=false) {
        if (date == "") return("");
        if (us) {
          var parts = date.split("/");
          return(parts[1] + "/" + parts[0] + "/" + parts[2]);
        }
        return(date);
      },
      setDates() {
        for(var i=0 ; i < this.selDates.length ; i++) {
          var date = this.vueToIntDate(this.selDates[i]);
          // Check date is not in the past
          if (this.pastDate(date)) {
            alert("Date " + this.selDates[i] + " is in the past!")
            return;
          }
          // Check the date isn't listed already
          for (var j=0 ; j < this.dates.length ; j++) {
            if (date == this.dates[j].date) {
              alert("The date " + this.selDates[i] + " is already included!");
              return;
            }
          }
        }
        for (var i=0 ; i < this.selDates.length ; i++) {
          var date = this.vueToIntDate(this.selDates[i]);
          var dateEntry = {date: date, availability:[]};
          this.dates.push(dateEntry);
          this.dateTags.push("");
          this.nbrDates++;
          for (var attIx=0 ; attIx < this.attendees.length ; attIx++)
            this.dates[this.nbrDates-1].availability.push('U');
        }

        this.dates.sort(function(a, b){
          var parts = a.date.split("/");
          var d1 = parts[1] + "/" + parts[0] + "/" + parts[2];
          parts = b.date.split("/");
          var d2 = parts[1] + "/" + parts[0] + "/" + parts[2];
          var date1 = new Date(d1);
          var date2 = new Date(d2);
          if (date1 < date2) return -1;
          if (date1 > date2) return 1;
          return 0;
        });
        this.nbrDisplayDates = Math.min(this.nbrDates, this.maxDisplayDates);
        this.dateOffset = 0;
        this.calendar = false;
        this.changes = true;     
        // Update best fit date
        this.calculateBestFitDate();
        this.selDates = [];
      },
      addNewAttendee() {
        // Check for duplicates
        if (this.attendees.indexOf(this.newName) != -1) {
          alert("Duplicate name - ensure all attendees have unique names");
          this.newName = "";
          return;
        }
        var lock = !this.create;
        if (lock)
          this.create = true;
        this.attendees.push(this.newName);
        this.nbrAttendees++;
        // Set all dates for this new attendee to 'U'
        for (var dateIx=0 ; dateIx < this.nbrDates ; dateIx++)
          this.dates[dateIx].availability.push('U');
        this.pins.push(this.setPIN());
        this.required.push(this.isRequired);
        this.organisers.push(this.isOrganiser);
        this.newName = "";
        this.nameField = false;
        this.isOrganiser = false;
        this.isRequired = false;
        if (lock)
          this.create = false;
        this.changes = true;     
        // Update best fit date
        this.calculateBestFitDate();
      },
      deleteDate(dateIx) {
        this.dates.splice(dateIx, 1);
        this.nbrDates--;
        this.changes = true;     
        // Update best fit date
        this.calculateBestFitDate();
      },
      deleteAttendee(ix) {
        if (!confirm("You will be deleting " + this.attendees[ix] + " and all their data from this meeting - are you sure?"))
          return;
        this.organisers.splice(ix, 1);
        this.required.splice(ix, 1);
        this.attendees.splice(ix, 1);
        this.pins.splice(ix, 1);
        for (var dateIx=0 ; dateIx < this.nbrDates ; dateIx++)
          this.dates[dateIx].availability.splice(ix, 1);
        this.nbrAttendees--;
        this.changes = true;     
        // Update best fit date
        this.calculateBestFitDate();
      },
      setAvailability(ixName, ixDate) {
        if (this.calendar || this.nameField)
          return;
        if (this.readOnly)
          return;
        if (!this.organiser && this.attendees[ixName] != this.myName)
          return;
        switch (this.dates[ixDate].availability[ixName]) {
          case 'Y': this.dates[ixDate].availability[ixName] = 'P'; break;
          case 'U': this.dates[ixDate].availability[ixName] = 'Y'; break;
          case 'P': this.dates[ixDate].availability[ixName] = 'N'; break;
          default : this.dates[ixDate].availability[ixName] = 'U'; break;
        }
        // Update best fit date
        this.calculateBestFitDate();
        this.$forceUpdate(); 
        this.changes = true;     
      },
      availColour(ixName, ixDate) {
        if (this.organiserOnly[ixName])
          return("white black--text");
        if (this.dates[ixDate].availability.length == 0)
          return("white black--text");
        switch (this.dates[ixDate].availability[ixName]) {
          case 'Y': return("green white--text");
          case 'N': return("red white--text"); 
          case 'P': return("orange white--text");
          default : return("white black--text");
        }
      },
      userColour(ix, gapOnly=false) {
        var classes = "mt-3";
        if (ix > 0 && this.comments[ix-1])
          classes = "mt-1" ;
        if (gapOnly)
          return(classes);
        if (this.attendees[ix] == this.myName)
          classes += " red--text";
        return(classes);
      },
      createLinks() {
        // Generate URL links for each attendee
        var path = "https://plansharemeet.com?meeting=" + this.meetingId + "&user=";
        this.linkField = "";
        for (var i=0 ; i < this.nbrAttendees ; i++) {
          this.linkField += this.attendees[i] + ": \n    " + path+this.pins[i] + "\n";
        }
        this.linksDialog = true;
      },
      copyLinks() {
        navigator.clipboard.writeText(this.linkField);      
      },
      dayOfWeek(date) {
        var parts = date.split('/');
        var d = parts[1] + "/" + parts[0] + "/" + parts[2];
        var thisDate = new Date(d);
        var day = thisDate.getDay();
        return(this.daysOfWeek[day+1]);
      },
      isPreferredDay(date) {
        // Check if date is a preferred one (if one is defined)
        if (!this.preferredDay || this.preferredDay == "None") return(false);
        return(this.dayOfWeek(date) == this.preferredDay);
      },
      distFromNominal(date) {
        // Number of days date is away from nominal date
        var parts = date.split('/');
        var d = parts[1] + "/" + parts[0] + "/" + parts[2];
        var d1 = new Date(d);
        parts = this.nominalDate.split('/');
        d = parts[1] + "/" + parts[0] + "/" + parts[2];
        var d2 = new Date(d);
        return(Math.abs(d1.getTime() - d2.getTime())/(1000 * 3600 * 24));
      },
      setAvailTexts() {
        for (var n=0 ; n < 2 ; n++) {
          var date = (n == 0) ? this.bestFit : this.chosenDate;
          var dateIx = 0;
          for (var i=0 ; i < this.dates.length ; i++)
            if (this.dates[i].date == date) {
              dateIx = i;
              break;
            }
          var nbrAvail = 0;
          var nbrMaybe = 0;
          var nbrUnknown = 0;
          var status = "";
          for (var i=0 ; i < this.nbrAttendees ; i++) {
            if (this.dates[dateIx].availability[i] == 'Y') nbrAvail++;
            if (this.dates[dateIx].availability[i] == 'P') {nbrAvail++;nbrMaybe++}
            if (this.dates[dateIx].availability[i] == 'U') nbrUnknown++;
          }
          if (nbrAvail == this.nbrAttendees) {
            status = "Everybody available";
            if (nbrMaybe > 0) status += " (" + nbrMaybe + " not preferred)";
          }
          else {
            status = nbrAvail + " out of " + this.nbrAttendees + " available";
            if (nbrMaybe > 0) status += " (" + nbrMaybe + " not preferred)";
            if (nbrUnknown > 0) status += " (" + nbrUnknown + " unknown/yet to commit)";
          }
          if (n == 0) this.bestFitText = status;
          else this.chosenText = status;
        }
      },
      calculateBestFitDate() {
        // Build an availability summary table
        if (this.nbrDates == 0)
          return;
        var availabilitySummary = [];
        for (var dateIx=0 ; dateIx < this.nbrDates ; dateIx++) {
          var nbrY = 0;
          var nbrN = 0;
          var nbrP = 0;
          var nbrU = 0;
          var allRequired = true;
          for (var attIx=0 ; attIx < this.nbrAttendees ; attIx++) {
            if (this.organiserOnly[attIx])
              continue;
            if (this.dates[dateIx].availability[attIx] == 'Y') nbrY++;
            else if (this.dates[dateIx].availability[attIx] == 'N') nbrN++;
            else if (this.dates[dateIx].availability[attIx] == 'P') nbrP++;
            else nbrU++;
            if (this.required[attIx] && this.dates[dateIx].availability[attIx] == 'N')
              allRequired = false;
          }
          var preferred = this.isPreferredDay(this.dates[dateIx].date);
          var score = nbrY*100 + nbrP*99 + nbrU*98;
          var distFromNominal = this.distFromNominal(this.dates[dateIx].date);
          availabilitySummary.push({nbrY: nbrY, nbrN: nbrN, nbrP: nbrP, nbrU: nbrU, preferred: preferred, score: score, 
            dist: distFromNominal, allRequired: allRequired});
        }
        // Select highest 'score' with all required people available - should reflect maximum availability. On a tie select:
        //   - a preferred day (if defined)
        //   - then, closest to the nominal date
        var bestDates = [];
        var highest = 0;
        for (var dateIx=0 ; dateIx < this.nbrDates ; dateIx++) {
          if (availabilitySummary[dateIx].allRequired && availabilitySummary[dateIx].score > highest)
            highest = availabilitySummary[dateIx].score;
        }
        for (var dateIx=0 ; dateIx < this.nbrDates ; dateIx++) {
          if (availabilitySummary[dateIx].score == highest)
            bestDates.push(dateIx);
        }
        // If no dates fit (only because one of required attendees can't make any dates) ste best fir to nominal date
        if (bestDates.length == 0) {
          this.bestFit = this.nominalDate;
        }
        // If only one at highest score use that one
        else if (bestDates.length == 1)
          this.bestFit = this.dates[bestDates[0]].date;
        else {
          // If we have a preferred day of the week filter those out (if any)
          if (this.preferredDay) {
            var preferredDates = [];
            for (var dateIx=0 ; dateIx < bestDates.length; dateIx++) {
              if (availabilitySummary[bestDates[dateIx]].preferred)
                preferredDates.push(bestDates[dateIx]);
            }
            // Replace table with these referred dates
            if (preferredDates.length > 0)
              bestDates = [...preferredDates];
          }
          // Now choose closest date to nominal (on a tie, earliest date is used)
          var dist = 1000;
          var selDate = this.dates[bestDates[0]].date;
          for (var dateIx=0 ; dateIx < bestDates.length ; dateIx++) {
            if (availabilitySummary[bestDates[dateIx]].dist < dist) {
              selDate = this.dates[bestDates[dateIx]].date;
              dist = availabilitySummary[bestDates[dateIx]].dist;
            }
          }
          this.bestFit = selDate;
        }
        // Set availability display texts for best + chosen dates (if set)
        this.setAvailTexts();
      },
      dateColour(date) {
        if (!this.ready) return;
        var style = {color:"black", backgroundColor:"white"};
        if (date == this.bestFit)
          style.color = "blue";
        if (date == this.chosenDate)
          style.backgroundColor = "yellow";
        //if (date == this.nominalDate)
        //  style.color = "blue";
        return(style);
      },
      commentBtnText(ix) {
        if (this.comments[ix]) 
          return("Edit comment");
        return("Add comment");
      },
      editComment(ix) {
        if (!this.organiser && this.attendees[ix] != this.myName)
          return;
        this.comment = this.comments[ix];
        this.commentIx = ix;
        this.commentDialog = true;
      },
      saveComment() {
        this.comments[this.commentIx] = this.comment;
        this.commentDialog = false;
        this.changes = true;
      },
      commentGap(ix) {
        return(this.comments[ix] ? "mb-0" : "mb-1");
      },
      attendeeText(ix) {
        if (!this.ready) return;
        var text = this.attendees[ix];
        if (this.organiserOnly[ix])  
          text += " (ORG ONLY)";
        else if (this.organisers[ix])  
          text += " (ORG)";
        if (this.required[ix])
          text += " (REQ)";
        return(text);
      },
      attendeeMenuText(attIx, menuIx) {
        if (!this.ready) return;
        switch (menuIx) {
          case 0: 
            return("Remove " + this.attendees[attIx] + " from meeting");
          case 1:
            if (this.required[attIx]) return("Remove " + this.attendees[attIx] + " as required attendee");
            return("Flag " + this.attendees[attIx] + " as required attendee");
          case 2:
            //if (this.organisers[attIx]) return("Remove " + this.attendees[attIx] + " as organiser");
            return("Flag " + this.attendees[attIx] + " as organiser");
          case 3:
            if (this.organiserOnly[attIx]) return("Remove " + this.attendees[attIx] + " as organiser only");
            return("Flag " + this.attendees[attIx] + " as organiser only");
        }
      },
      attendeeMenuDisabled(attIx, menuIx) {
        switch (menuIx) {
          case 0:
            // Disable if only organiser
            if (this.organisers[attIx] && this.organisers.filter(x => x === true).length == 1) 
              return(true);
            break;
          case 1:
            // Change required attendee status
            if (this.organisers[attIx] && this.organisers.filter(x => x === true).length == 1) 
              return(true);
            if (this.organiserOnly[attIx]) return(true);
            break;
          case 2:
            //if (this.organisers[attIx] && this.organisers.filter(x => x === true).length == 1) 
            if (this.organisers[attIx])
              return(true);
            break;
          case 3:
            // Change organiser only status (must be organiser)
            return(!this.organisers[attIx]);
        }
        return(false);
      },
      attendeeMenuAction(attIx, menuIx) {
        switch (menuIx) {
          case 0: 
            // Remove attendee (unless only organiser)
            if (this.organisers[attIx] && this.organisers.filter(x => x === true).length == 1) {
              alert("Cannot remove sole organiser!");
              return;
            }
            this.deleteAttendee(attIx);
            break;
          case 1:
            // Change required attendee status
            this.required[attIx] = !this.required[attIx];
            break;
          case 2:
            // Change organiser status (ensure at least one is left)
            //if (this.organisers[attIx] && this.organisers.filter(x => x === true).length == 1) {
            //  alert("Cannot remove organiser status as there must be at least one organiser!");
            //  return;
            //}
            for (var i=0 ; i < this.organisers.length; i++) {
              if (this.organisers[i]) {
                if (confirm("Change organiser from " + this.attendees[i] + " to " + this.attendees[attIx] + "?")) {
                  this.organisers[i] = false;
                  this.organisers[attIx] = true;
                  this.organiser = false;
                  this.changes = true;
                }
                break;
              }
            }
            break;
          case 3:
            // Change organiser only status (must be organiser)
            if (this.organisers[attIx])
              this.organiserOnly[attIx] = !this.organiserOnly[attIx];
            else
              alert("Must be an organiser first!");
            break;
        }
        this.changes = true;
        this.$forceUpdate();      
      },
      dateMenuText(dateIx, menuIx) {
        var date = this.dates[dateIx].date;
        date = this.formatDate(date, !this.intDateFormat);
        switch (menuIx) {
          case 0: 
            return("Remove " + date + " from meeting");
          case 1:
            return("Set " + date + " as Nominal date");
          case 2:
            return("Add/edit date tag/comment for " + date);
          case 3:
            if (date == this.chosenDate)
              return("Clear chosen date");
            return("Set " + date + " as chosen date");
        }
      },
      dateMenuDisabled(dateIx, menuIx) {
        switch (menuIx) {
          case 0:
            // Disable if nominal date
            if (this.dates[dateIx].date == this.nominalDate) 
              return(true);
            break;
          case 1:
            // Disable if already nominal date
            if (this.dates[dateIx].date == this.nominalDate) 
              return(true);
            break;
          case 2:
            return(false);
        }
        return(false);
      },
      dateMenuAction(dateIx, menuIx) {
        switch (menuIx) {
          case 0: 
            // Remove date
            this.deleteDate(dateIx);
            break;
          case 1:
            // Set as nominal date
            this.nominalDate = this.dates[dateIx].date;
            break;
          case 2:
            // Add/edit date tag
            this.dateIx = dateIx;
            this.dateTag = this.dateTags[dateIx];
            this.dateTagDialog = true;
            return;
          case 3:
            // Set as chosen date (or clear if requested)
            if (this.dates[dateIx].date == this.chosenDate)
              this.chosenDate = "";
            else
              this.chosenDate = this.dates[dateIx].date;
            break;
        }
        this.calculateBestFitDate();
        this.changes = true;
      },
      saveDateTag() {
        this.dateTags[this.dateIx] = this.dateTag;
        this.dateTagDialog = false;
        this.changes = true;
      },
      haveDateTag() {
        for (var i=0 ; i < this.dateTags.length ; i++)
          if (this.dateTags[i]) return(true);
        return(false);
      },
      laterDates() {
        this.dateOffset += this.maxDisplayDates;
        this.nbrDisplayDates = Math.min(this.maxDisplayDates, this.nbrDates-this.dateOffset);
      },
      earlierDates() {
        this.dateOffset = Math.max(0, this.dateOffset-this.maxDisplayDates);
        this.nbrDisplayDates = this.maxDisplayDates;
      },
      firstDates() {
        this.dateOffset = 0;
        this.nbrDisplayDates = Math.min(this.maxDisplayDates, this.nbrDates);
      },
      async forceUnlock() {
        if (!confirm("This will override the meeting lock - are you sure?"))
          return;
        await this.db.collection("meetings").doc(this.meetingId).update({lockId: 0, locked: false});   
        this.locked = false;
        this.organiser = true;  
        this.readOnly = false;   
      },
      attSpacing(attIx) {
        if (attIx > 0 && this.comments[attIx-1])
          return("mt-2");
        return("");
      },
      async checkInUse() {
        console.log("meetingView = " + this.meetingView)
        if (!this.meetingView)
          return(false);
        var prompt = true;
        this.$store.commit('changeMeetingId', "");
        console.log("CHECK INUSE");
        if (this.meetingView && this.changes) {
          prompt = !confirm("Exiting will lose any changes you have made. Are you sure?");
        }
        if ((this.meetingView && !this.readOnly) && !this.create || false) {
          this.meetingView = false;
          console.log("Decrementing inUse")
          var meetingRef = this.db.collection("meetings").doc(this.meetingId);
          await this.db.runTransaction(async (transaction) => {
            const snapshot = await transaction.get(meetingRef);
            // Decrement inUse counter but ensure it is not < 0
            if (snapshot.data().inUse < 1)
              await transaction.update(meetingRef, {inUse: 0}); 
            else       
              await transaction.update(meetingRef, {inUse: window.fsFunc.FieldValue.increment(-1)});        
          });
        }
        return(prompt);
      },
    },
    async created() {
      window.addEventListener('beforeunload', async (e) => {
        // Called when quitting browser        
        var inUse = await this.checkInUse();
        if (inUse) {
          console.log("TRUE")
          e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
          // Chrome requires returnValue to be set
          e.returnValue = '';
        }
      }, false);

      if (this.$store.state.meetingId) {
        this.meetingId = this.$store.state.meetingId;
        if (this.$store.state.userId)
          this.userId = this.$store.state.userId;
        await this.openMeeting();
      }
      else {
        this.meetingName = "";
        this.meetingId = "";
        this.userId = "";
      }
    },
    beforeDestroy: async function() {
      // Called when exiting meeting component
      console.log("DESTROY");
      //alert("DESTROY")
      await this.checkInUse();
    },
  }
</script>

<style>
  .v-textarea textarea {
      line-height: 15px !important;
      color: blue !important;
  }
</style>
  
