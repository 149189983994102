import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    meetingId: "",
    userId: "",
  },
  getters: {
  },
  mutations: {
    changeMeetingId(state, str) {state.meetingId = str;},
    changeUserId(state, str) {state.userId = str;},
  },
  actions: {
  },
  modules: {
  }
})
