import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
//import { initializeApp } from 'firebase/app';
//import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
//import firebase from 'firebase'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

/*eslint no-unused-vars: "off"*/

Vue.config.productionTip = false

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAssWL2gqyJ634kWFcsrdCRfiVbfMtWc9I",
  authDomain: "plansharemeet.firebaseapp.com",
  projectId: "plansharemeet",
  storageBucket: "plansharemeet.appspot.com",
  messagingSenderId: "261436367352",
  appId: "1:261436367352:web:b331d6fb5d5f404d5c760d",
  measurementId: "G-2LCMTQKSSQ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
console.log("Initialised link to Firestore");
window.db = firebase.firestore();
window.fsFunc = firebase.firestore;
//const app = initializeApp(firebaseConfig);
//const db = getFirestore(app);
//window.app = app;
//window.db = db;

new Vue({
  created() {
    // Check for url query parms
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('meeting')) {
      var meeting = urlParams.get('meeting');
      store.commit('changeMeetingId', meeting);
      if (urlParams.has("user")) {
        var id = urlParams.get('user');
        store.commit('changeUserId', id);
      }
      else
        store.commit('changeUserId', "");
      console.log("Started with meeting ID = " + meeting + ", userId = " + id);
    }
    else {
      store.commit('changeMeetingId', "");
      store.commit('changeUserId', "");
    }
  },
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
